import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import LogoHeaderTransparent from "assets/logo/logo-transparent.png";
import {mediaQuery} from "components/common/styled/mediaQuery";
import routes from "consts/routes";

const LogoContainer = styled.div`
    display: flex;
    align-items: center;

    img {
        height: 3.625rem;
        ${mediaQuery.phone`height: 2.5rem;`}
    }
`;

const MenuText = styled.span`
    margin-left: 1rem;
    color: black;

    :hover {
        text-decoration: underline;
    }
`;

const LogoTransparent = ({t, ...props}) => (
    <LogoContainer>
        <Link id="tabseraLogo" to={routes.home.path}>
            <img src={LogoHeaderTransparent} alt={"logo"} {...props} />
        </Link>
        <Link to={routes.mainDashboard.path} style={{textDecoration: "none"}}>
            <MenuText>{t("sms.mainDashboard.mainDashboard")}</MenuText>
        </Link>
    </LogoContainer>
);

export default LogoTransparent;
