import React from "react";
import NetworkController from "controllers/Network";
import AsyncStateComponent from "./common/AsyncStateComponent";
import {withContextConsumer} from "../utils/contexts";
import CurrentUserContext from "./CurrentUser";
import userTypes from "consts/userTypes";
import GlobalHintsContext from "./GlobalHints";

const WalletContext = React.createContext("wallet");

@withContextConsumer(GlobalHintsContext.Consumer)
@withContextConsumer(CurrentUserContext.Consumer)
class WalletProvider extends AsyncStateComponent {
    constructor(props) {
        super(props);
        this.state = {
            wallet: null,
            meta: null,
            earnings: 0,
            paypalLibraryDidLoaded: false,
            incomeTransactionError: null,
            transactionInProgress: false,
            payoutInProgress: false,
            payoutError: null,
            ownPayHistory: {},
            paymentModalOpenParams: null,
            telrContentIsLoading: false,
            waafiContentIsLoading: false,
            openTelrContent: this.openTelrContent.bind(this),
            openWaafiContent: this.openWaafiContent.bind(this),
            setPaymentModalOpenParams: paymentModalOpenParams => this.setState({paymentModalOpenParams}),
            getWallet: this.getWallet.bind(this),
            loadPaypalLibrary: this.loadPaypalLibrary.bind(this),
            changeCurrency: this.changeCurrency.bind(this),
            onPaypalPaymentApproved: this.onPaypalPaymentApproved.bind(this),
            onTelrPaymentApproved: this.onTelrPaymentApproved.bind(this),
            onWaafiPaymentFailed: this.onWaafiPaymentFailed.bind(this),
            onSavePayoutSettings: this.onSavePayoutSettings.bind(this),
            onMakePayout: this.onMakePayout.bind(this),
            getOwnPayHistory: this.getOwnPayHistory.bind(this),
            getBalanceRechargeAmount: this.getBalanceRechargeAmount.bind(this),
            getEarnings: this.getEarnings.bind(this),
            convertMoney: this.convertMoney.bind(this),
            onUnknownTransactionError: this.onUnknownTransactionError.bind(this),
            isWalletLoading: false,
            getMeta: this.getMeta.bind(this)
        };
    }

    async componentDidMount() {
        const {currentUser} = this.props;
        if (!currentUser) {
            return;
        }
        const isUserSchoolTeacher =
            currentUser.role === userTypes.teacher && currentUser.schoolSettings && currentUser.schoolSettings.school;
        const isAdmin = currentUser.role === userTypes.admin;
        if (!isAdmin && !isUserSchoolTeacher) {
            await this.loadData();
        }
    }

    async componentDidUpdate(prevProps) {
        if (!prevProps.currentUser && this.props.currentUser) {
            await this.loadData();
        }
    }

    async loadData() {
        await this.getMeta();
        await this.getWallet();
    }

    async getMeta() {
        const {response} = await NetworkController.get("/wallet/meta");
        await this.setStatePromise({meta: response});
    }

    async getWallet() {
        await this.setStatePromise({isWalletLoading: true});
        const {response} = await NetworkController.get("/wallet");
        await this.setStatePromise({wallet: response.wallet, isWalletLoading: false});
    }

    async changeCurrency(currencyName) {
        const {currentUser} = this.props;
        const isTeacher = currentUser.role === userTypes.teacher;

        const {response} = await NetworkController.post("/wallet/currency", {currencyName});
        await this.setStatePromise({wallet: response.wallet});

        if (isTeacher) {
            await this.getEarnings();
        }
    }

    async doPaymentTransaction(url, body, callback) {
        await this.setStatePromise({transactionInProgress: true, incomeTransactionError: null});
        const {error, response} = await NetworkController.post(url, body);
        await this.setStatePromise({
            transactionInProgress: false,
            incomeTransactionError: error ? response.message : null,
            wallet: error ? this.state.wallet : response.wallet
        });
        !error && callback && (await callback());
    }

    async onPaypalPaymentApproved(orderID) {
        const {callback} = this.state.paymentModalOpenParams;
        await this.setStatePromise({paymentModalOpenParams: null});
        await this.doPaymentTransaction("/wallet/paypal/transaction", {orderID}, callback);
    }

    async onTelrPaymentApproved() {
        const {paymentModalOpenParams} = this.state;
        const {callback} = paymentModalOpenParams;
        await this.doPaymentTransaction("/wallet/telr/transaction", {ref: paymentModalOpenParams.data.ref}, callback);
        await this.setStatePromise({paymentModalOpenParams: null});
    }

    async onWaafiPaymentFailed(error) {
        await this.setStatePromise({incomeTransactionError: error});
    }

    async onSavePayoutSettings(data) {
        const {response, error} = await NetworkController.put("/wallet/payout/settings", data);
        if (error) {
            return await this.setStatePromise({payoutError: response.message});
        }
        await this.setStatePromise({wallet: response.wallet, payoutError: null});
    }

    async onMakePayout(payoutSystem, amount) {
        await this.setStatePromise({payoutInProgress: true});
        const {error, response} = await NetworkController.post(`/wallet/${payoutSystem}/payout`, {amount});
        await this.setStatePromise({payoutInProgress: false, wallet: error ? this.state.wallet : response.wallet});
    }

    async getOwnPayHistory(type, offset, limit) {
        const {response} = await NetworkController.get("/wallet/history", {type, offset, limit});
        await this.setStatePromise({ownPayHistory: {...this.state.payHistory, [type]: response}});
    }

    async openTelrContent(amount) {
        await this.setStatePromise({telrContentIsLoading: true});
        const {response} = await NetworkController.get("/wallet/telr/modal", {amount});
        this.setState({
            paymentModalOpenParams: {...this.state.paymentModalOpenParams, stage: "telr", data: response.modal},
            telrContentIsLoading: false
        });
    }

    async openWaafiContent(amount, callbackLink, mode) {
        await this.setStatePromise({waafiContentIsLoading: true});

        let paymentMode = "mobile";
        if (mode === "card") {
            paymentMode = "card";
        }
        const {response} = await NetworkController.get(`/wallet/waafi/modal`, {amount, callbackLink, mode: paymentMode});
        if (response.message) {
            return this.setState({
                paymentModalOpenParams: null,
                incomeTransactionError: response.message,
                waafiContentIsLoading: false
            });
        }
        this.setState({
            paymentModalOpenParams: {...this.state.paymentModalOpenParams, stage: "waafi", data: response.modal},
            waafiContentIsLoading: false
        });
    }

    async getEarnings() {
        const {response} = await NetworkController.get("/wallet/earnings");
        this.setState({earnings: response.earnings});
    }

    loadPaypalLibrary() {
        return new Promise((resolve, reject) => {
            const {wallet, meta} = this.state;
            const script = document.createElement("script");

            script.onload = () => resolve();
            script.onerror = () => reject(new Error("Failed to load PayPal library."));

            script.src = `https://www.paypal.com/sdk/js?client-id=${
                meta.paypal.clientId
            }&disable-funding=credit,card,sepa&currency=${wallet.account.currency.name}`;

            document.head.appendChild(script);
        });
    }

    getBalanceRechargeAmount(grossAmount) {
        const {wallet} = this.state;
        const account = wallet.account;
        const currencyName = account.currency.name.toLowerCase();
        const paymentSystems = account.currency.info.paymentSystems;
        const fee = account.currency.info[paymentSystems[0]].fee;

        grossAmount = grossAmount[currencyName] || grossAmount;
        const netAmount = Math.ceil(grossAmount - account.value);
        if (!fee) {
            return netAmount;
        }
        return Math.ceil(netAmount + netAmount * (fee.income.percentageValue / 100) + fee.income.perTransactionValue);
    }

    onUnknownTransactionError() {
        this.props.addHint({text: "Error processing payment, please try another payment method", timeout: 3000});
    }

    async convertMoney(from, to, amount) {
        if (from === to) {
            return amount;
        }
        const {response} = await NetworkController.post("/wallet/currencies/convert", {from, to, amount});
        return response.amount;
    }

    render() {
        return <WalletContext.Provider value={this.state}>{this.props.children}</WalletContext.Provider>;
    }
}

export default {Provider: WalletProvider, Consumer: WalletContext.Consumer};
