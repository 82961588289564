import React, {Component} from "react";
import ModalLeftHeader from "components/common/modals/ModalLeftHeader";
import PaymentMethods from "consts/paymentMethods";
import TelrPaymentModalContent from "./TelrPaymentModalContent";
import DefaultPaymentModalContent from "./DefaultPaymentModalContent";
import WaafiPaymentModalContent from "./WaafiPaymentModalContent";
import Spinner from "../../../common/Spinner";

class PaymentModal extends Component {
    constructor(props) {
        super(props);
        const availablePaymentMethods = props.currency.info.paymentSystems;
        const paymentMethod = availablePaymentMethods[0];
        const amount = Math.max(props.currency.info[paymentMethod].minimumIncome, props.params.amount || 0);

        this.state = {
            amount,
            paymentMethod,
            availablePaymentMethods,
            loading: true,
            paymentButtonDisabled: false
        };
    }

    async componentDidMount() {
        const {availablePaymentMethods} = this.state;
        if (availablePaymentMethods.includes(PaymentMethods.paypal)) {
            try {
                await this.props.loadPaypalLibrary();
            } catch (e) {
                // if paypal is not supported, remove paypal from available payment methods
                const newAvailablePaymentMethods = availablePaymentMethods.filter(paymentMethod => paymentMethod !== "paypal");
                this.setState({
                    availablePaymentMethods: newAvailablePaymentMethods,
                    paymentMethod: newAvailablePaymentMethods[0]
                });
            }
        }
        this.setState({loading: false});
    }

    selectPaymentMethod(paymentMethod) {
        this.setState({paymentMethod});
    }

    setAmount(amount) {
        const {currency} = this.props;
        const {paymentMethod} = this.state;
        const currencyInfo = currency.info[paymentMethod];
        const paymentButtonDisabled = !amount || amount < currencyInfo.minimumIncome || amount > currencyInfo.maximumIncome;
        this.setState({amount, paymentButtonDisabled});
    }

    render() {
        const {t, params, onTelrPaymentApproved, onWaafiPaymentApproved, onUnknownTransactionError, onRequestClose} = this.props;
        const {loading} = this.state;

        const currentStage = params.stage || "default";

        return (
            <ModalLeftHeader
                onRequestClose={onRequestClose}
                currentStage={currentStage}
                title={t("wallet.payment.title")}
                style={{width: "32rem"}}
                isOpen>
                {loading ? (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: "3rem"
                        }}>
                        <Spinner height="4rem" stroke="var(--main-theme-color)" />
                    </div>
                ) : (
                    <>
                        {currentStage === "default" && (
                            <DefaultPaymentModalContent
                                {...this.props}
                                {...this.state}
                                onPaymentMethodChange={paymentMethod => this.setState({paymentMethod})}
                                setAmount={this.setAmount.bind(this)}
                            />
                        )}
                        {currentStage === "telr" && (
                            <TelrPaymentModalContent
                                params={this.props.params.data}
                                onRequestClose={onRequestClose}
                                onUnknownTransactionError={onUnknownTransactionError}
                                onTelrPaymentApproved={onTelrPaymentApproved}
                            />
                        )}
                        {currentStage === "waafi" && (
                            <WaafiPaymentModalContent
                                params={this.props.params.data}
                                onRequestClose={onRequestClose}
                                onUnknownTransactionError={onUnknownTransactionError}
                                onTelrPaymentApproved={onWaafiPaymentApproved}
                            />
                        )}
                    </>
                )}
            </ModalLeftHeader>
        );
    }
}

export default PaymentModal;
